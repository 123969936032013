@import 'react-table-v6/react-table.css';
@import 'sass/base/variables';
@import 'sass//base/variables';

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-noData {
  text-transform: capitalize !important;
  margin-top: 55px;
  z-index: unset;
}

.rt-resizable-header-content .csort-desc:before,
.rt-resizable-header-content .csort-desc:after,
.rt-resizable-header-content .csort-asc:before,
.rt-resizable-header-content .csort-asc:after {
  border: 4px solid transparent;
  content: '';
  display: block;
  height: 0;
  right: 0;
  top: 50%;
  position: absolute;
  width: 0;
}

.rt-resizable-header-content .csort-desc:before,
.rt-resizable-header-content .csort-asc:before {
  border-bottom-color: theme('colors.primaryBlue.1');
  margin-top: -9px;
}

.rt-resizable-header-content .csort-desc:after,
.rt-resizable-header-content .csort-asc:after {
  border-top-color: theme('colors.primaryBlue.2');
  margin-top: 1px;
}

.rt-resizable-header-content .csort-asc:before {
  border-bottom-color: theme('colors.primaryBlue.2');
}

.rt-resizable-header-content .csort-asc:after {
  border-top-color: theme('colors.primaryBlue.1');
}

.ReactTable,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td,
.ReactTable .-pagination {
  border: none;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .-pagination {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th{
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
}

.ReactTable .rt-tbody .rt-thead.-header {
  text-transform: uppercase;
  position: sticky;
  top: 0;
  background: $sky-white;
  border-radius: $md-radius;
  padding: 0px 10px;
  color: $head-black;
}

.ReactTable .rt-thead.-header {
  text-transform: uppercase;
  position: sticky;
  top: 0;
  
  //padding: 0px 10px;
  border-bottom: 1px solid $stroke;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
  align-items: center;
}

.left-aligned-table {
  .rt-th {
    text-align: left !important;
  }

  .rt-tbody .rt-td {
    text-align: left !important;
  }
}

.aligned-no-row .rt-noData {
  text-transform: capitalize !important;
  margin-top: 30px !important;
  background-color: transparent !important;
}

.ReactTable.no-data-with-pagination .rt-noData {
  margin-top: 25px !important;
  margin-bottom: 10px;
  background: transparent;
}

.no-data-with-pagination .rt-tbody .rt-td {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 10px;
}

.modal-search-select .css-1s2u09g-control,
.modal-search-select .css-1pahdxg-control {
  font-size: 0.9125em;
  height: $lg-height;
  border-color: $input-gray;
  width: 16em;
  border-radius: $md-radius;
  font-size: 0.9125em;

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
}

.editor-wrap,
.description-wrap {
  // background-color: #ffffff;
  // max-height: 300px;
  // overflow-y: auto;
  // .ql-toolbar.ql-snow .ql-formats {
  //   margin-right: 0px;
  // }

  .ql-container {
    // border-bottom-left-radius: 0.5em;
    // border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  /* Snow Theme */
  .ql-snow.ql-toolbar {
    display: block;
    background: #fefcfc;
    // border-top-left-radius: 0.5em;
    // border-top-right-radius: 0.5em;
  }

  /* Bubble Theme */
  .ql-bubble .ql-editor {
    // border: 1px solid #ccc;
    border-radius: 0.5em;
  }

  .ql-editor {
    min-height: 18em;
    border: 1px solid #dde1e7 !important;
  }

  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }
}

.description-wrap {
  max-height: 400px;
  overflow-y: auto;
}

.table-pagination {
  .disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.task-table {
  .rt-noData {
    margin-top: 15px;
    padding: 0;
  }

  .rt-table .rt-tbody {
    min-width: min-content !important;
  }
}

.sub-table-task {
  .rt-table .rt-tbody .rt-td {
    // margin-left: 20px;
  }

  .rt-noData {
    margin-top: 0 !important;
    padding: 0;
  }
}

.inner-table-border {
  padding: 0 1em 1em 4.4em;

  .rt-resizable-header-content {
    line-height: 14px !important;
  }

  .rt-tr-group {
    border: 1px solid #e8eff5;
    // margin-bottom: 20px;
  }

  .inner-table-no-border {
    .rt-tr-group {
      border: none;
    }

    .rt-th,
    .rt-td {
      overflow: visible;
    }
  }

  .rt-tbody .rt-td {
    padding-left: 1.5em;
  }
}

.no-scroll-tariff {
  .rt-tbody {
    overflow: hidden !important;
  }

  .no-scroll-tariff-1 .rt-table {
    overflow: hidden !important;
  }

  .no-scroll-tariff-1 .rt-tbody .rt-tr-group {
    padding-left: 2em;
  }
}

// inner tables

.card-table-sec {
  .ReactTable {
    .rt-noData {
      text-transform: capitalize !important;
      margin-top: 25px;
      background-color: transparent;
    }

    .rt-tbody .rt-thead.-header {
      padding: 0%;
      border-radius: 8px 8px 0px 0px !important;
    }

    .rt-tbody .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      background: $white;
      margin-bottom: 1em;
      box-shadow: $shadow-light;
    }

    .rt-th {
      text-align: left !important;
    }

    .rt-tbody .rt-td {
      text-align: left !important;
    }

    .-odd,
    .-even {
      box-shadow: $shadow-light;
      border: 0;
    }

    .sub-table {
      background-color: $white;

      .ReactTable {
        margin-left: 5em;
        margin-top: 1em;
        margin-right: 1em;

        .rt-tr-group {
          margin-bottom: 0;

          background-color: $white;
          border-bottom: 1px solid $stroke;
        }

        .-odd,
        .-even {
          box-shadow: none;
          background-color: #fff;
          border: none;
        }

        .rt-tr {
          margin-top: 0;

          &:hover {
            background-color: #f7faff;
          }
        }

        .rt-thead {
          .rt-th {
            padding: 8px 10px;
            font-size: 12px;
          }
          .rt-resizable-header-content, .-header {
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;
          }
        }

        .rt-td {
          padding: 2px 10px;
          font-size: 13px;
        }

        .super-sub-table {
          .table-wrapper {
            border: 1px solid $stroke;
            margin-left: 10em;
            margin-right: 1em;
            margin-bottom: 1em;
            border-radius: 8px;

            .ReactTable {
              margin: 0;

              .rt-tr-group {
                box-shadow: none;
                background-color: transparent;
                border: none;
                border-radius: none;
                margin: 0;
              }

              .rt-td {
                padding: 6px 10px;
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.tb-gt-1 {
  .ReactTable {
    .rt-tbody .rt-thead.-header {
      padding: 0%;
    }

    .rt-th {
      text-align: left !important;
    }

    .rt-tbody .rt-td {
      text-align: left !important;
    }

    .rt-tbody {
      overflow: hidden;
    }

    .tb-gt-2 {
      .ReactTable {
        .rt-td:nth-child(1),
        .rt-td:nth-child(2),
        .rt-td:nth-child(3) {
          padding-left: 2em;
        }

        .tb-gt-3 {
          .ReactTable {
            .rt-td:nth-child(1),
            .rt-td:nth-child(2),
            .rt-td:nth-child(3) {
              padding-left: 3em;
            }
          }
        }
      }
    }
  }
}
.tb-component {
  .verticalLine {
    border-left: 3px dotted #d4d4d4;
    margin-top: -12px;
    margin-left: 17px;
    .ReactTable .rt-thead{
      display: none;
    }
  }
  .color-tree-icon{
    font-size: 8px;
    color: #cae0ff;
  }
  .ReactTable .rt-resizable-header-content {
    text-align: left !important;
  }
}
.no-row-empty .rt-noData {
  margin-top: 15px !important;
}
.spec-table-container .ReactTable .rt-tbody{
  // min-width: 1327px !important;
  max-height: calc(100vh - 380px) !important;
  overflow-y: auto !important;
  .ReactTable .rt-tbody{
    max-height: max-content !important;
    overflow-x: hidden !important;
    min-width: auto !important;
    overflow-y: hidden !important;
  }
}
.task-table .rt-tbody{
  // min-width: 1327px !important;
  max-height: calc(100vh - 440px) !important;
  overflow-y: auto !important;
  padding: 18px !important;
  .ReactTable .rt-tbody{
    padding: 0px !important;
    overflow-x: hidden !important;
    min-width: auto !important;
  }
}
.table-scroll .rt-tbody{
  // min-width: 1327px !important;
  max-height: calc(100vh - 352px) !important;
  overflow-y: auto !important;
  scrollbar-color: #555969 #e7ecf0;
  scrollbar-width: thin;
  
  .ReactTable .rt-tbody{
    overflow-x: hidden !important;
    min-width: auto !important;
  }
}
.gt-extraheight .rt-tbody {
  max-height: calc(100vh - 334px) !important;
  overflow-y: auto !important;
  scrollbar-color: #555969 #e7ecf0;
  scrollbar-width: thin;
  
  .ReactTable .rt-tbody{
    overflow-x: hidden !important;
    min-width: auto !important;
  }
}
.component-scroll
{
  max-height: calc(100vh - 140px) !important;
  overflow-y: auto !important;
}
.section-Card-table{
  .table-right-header{
    .rt-thead{
      text-align: right;
    }
  }
  .rt-noData{
    margin-top: 0px !important;
    background-color: transparent;
  }
  .rt-th{
    white-space: normal;
    text-align: right;
  }
  .rt-tbody .rt-th:nth-child(1) {
    text-align: left;
  }
  .rt-thead .rt-tr{
    background-color: #EBF2FF;
    text-transform: uppercase;
  }
  .rt-tbody .rt-td{
    border-right: 1px solid $body-gray;
  }
}
.section-Card-table-rm-border{
  .rt-tbody .rt-td:nth-child(1), .rt-tbody .rt-td:nth-child(2) {
    border-right: none;
  }
  .rt-tbody .rt-th:nth-child(1), .rt-tbody .rt-th:nth-child(2) {
    text-align: left;
  }
}

.rfq-date .custom-select__menu-list {
  max-height: 11rem !important;
}
