// Colors

$black: #000;
$brand-color: #163ca5;
$hover-color: #48a5e5;
$second-brand-color: #48a5e5;

$red: #D1414B;
$green: #36AC79;
$orange: #fa7900;
$yellow: #facc00;
$blue: #007bff;

$body-white: #fafafa;
$sky-white: #EBF2FF;
$sky-blue: #e1e6ff;
$body-gray: #F2F3F7;
$hover-blue: #ebf4fd;

$white: #fff;
$grey: #e5e1e6;
$stroke: #eee;
$border-gray:#ddd;
$input-gray:#ccd2da;

$head-black: #212830;
$content-black: #41505f ;
$menu-color: #6e7985;


//Indicator

$p-ind: #56c1b2;
$s-ind:#3cabf0;
$r-ind: #6499c3;
$t-ind:#111961;
$c-ind:#babdba;

$current-actual:#0D2E6F;
$deviation: #005FAD;
$estimate: #01CEC2;

label {
    color: $content-black;
}

span.high {
    color: $red;
}
span.blue {
    color: $hover-color;
}
span.skyblue {
    color: $s-ind;
}
span.skywhite { 
    color: #EBF2FF;
}
span.medium {
    color: $orange;
}
span.low {
    color: #555969;
}
span.pending {
    color: $grey;
}

span.active {
    color: $green;
}
span.pending {
    color: $yellow;
}
span.submit {
    color: $blue;
}

// Fonts

$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Open Sans', sans-serif;

// Abstracts

$shadow-light: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%), 0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
$shadow-medium: 0px 4px 10px rgba($black, .2);

$sm-radius: 4px;
$md-radius: 6px;
$lg-radius: 8px;
$xl-radius: 12px;
$xxl-radius: 54px;

$sm-height: 1.625em;
$md-height: 2.25em;
$lg-height: 2.875em;
$xl-height: 3.45em;


label {
    color: $content-black;
}

.value {
    color: $head-black;
    font-weight: 500;
}

.white {
    color: $white;
}

.bg-brand-color {
    background-color: $brand-color;
}
.brand-color {
    color: $brand-color;
}

.second-brand-color {
    color: $second-brand-color;
}

.grey {
    color: $grey;
}

.green {
    color: $green;
}
.red {
    color: $red !important;
}

.bg-white {
    background-color: $white !important;
}

.bg-sky-white {
    background-color: $sky-white !important;
}

.bg-sky-blue {
    background-color: $sky-blue;
}

.bg-gray {
    background-color: $grey;
}

.body-gray{
    background-color: $body-gray;
}

.bg-green {
    background-color: $green;
}

.bg-red {
    background-color: $red;
}

.bg-orange {
    background-color: $orange;
}

.bg-lightorange {
    background-color: #FFF4D7;
}



.sm-radius {
    border-radius: $sm-radius;
}

.md-radius {
    border-radius: $md-radius;
}

.lg-radius {
    border-radius: $lg-radius;
}

.xl-radius {
    border-radius: $xl-radius;
}

.head-black {
    color: $head-black;
}

.w-full {
    width: 100%;
}
.w-98 {
    width: 98%;
}
.h-full {
    height: 100%;
}

.h-48 {
    height: 48px;
}
.h-260 {
    height: 2.61em;
}
.h-65 {
    height: 65px;
}
.w-33 {
    width: 33.33%;
}
.ws-nowrap {
    white-space: nowrap;
}
.width-fit {
    width: fit-content;
}

span.plans {
    color: $p-ind;
}

span.specifys {
    color: $s-ind;
}

span.repairs {
    color: $r-ind;
}

span.tenders {
    color: $t-ind;
}

span.closes {
    color: $c-ind;
}
.sidepanel-contact{
    background-color:$white !important ;
}
.orange{
  color: $orange;
}