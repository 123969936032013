@import './components/icons';
@import './components/animate';
@import './components/assets';
@import './components/grid';
@import './components/header';

.animate__animated {
  animation-fill-mode: none !important;
}

.wrapper {
  min-height: calc(100vh - 16em);
}

label {
  font-weight: normal;
}

.projectlist-wrapper {
  min-height: calc(100vh - 3em);
}

.tsitask-wrapper {
  min-height: calc(100vh - 425px);
}

.phase-wrapper {
  min-height: calc(100vh - 13em);
}

@keyframes swing {

  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

@media only screen and (max-width: 1200px) {
  .hamb-menu {
    display: none !important;
  }
}

.side-menu-wrap {
  background-color: $white;
  position: fixed;
  left: 0;
  min-height: 100vh;
  padding: 8em 0.8em 0.8em 0.8em;
  font-size: 13px;
  width: 110px;
  top: 0;
  z-index: 8;
  box-shadow: 1px 0 1px rgb(33 40 48 / 1%), 4px 0 4px rgb(33 40 48 / 1%), 16px 0 16px rgb(33 40 48 / 1%);


  @include respond(w1200) {

    &,
    &.expanded {
      width: 110px !important;

      .menu-item li {
        display: block !important;

        &.active {
          background-color: transparent !important;

          .icon {
            background-color: $brand-color !important;
            color: $white !important;
          }
        }

        p {
          display: block !important;
        }

        &.sub-head {
          display: none !important;
        }

        .icon {
          width: 42px !important;
          height: 42px !important;
          border-radius: 50% !important;
          background-color: #EBF2FF !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          margin: 0 auto 10px auto !important;
          color: #6e7985 !important;
        }
      }
    }
  }

  &.admin {
    padding: 10em 0.8em 0.8em 0.8em;

    .scroll-area {
      max-height: calc(100vh - 11em);
    }
  }

  &.user-role {
    padding: 10em 0.8em 0.8em 0.8em;
  }

  .sub-head {
    display: none;
    text-align: left;
  }


  &.expanded {
    width: 282px;

    .menu-item {
      gap: 0.5em;

      .sub-head {
        display: block;
      }

      li {
        cursor: pointer;
        display: flex;
        padding: 0.5em 0;
        border-radius: $md-radius;

        &:hover,
        &.active {
          background-color: $sky-white;
          color: $brand-color;
          transition: 0.5s ease;

          .icon {
            span {
              animation: swing ease-in-out 0.5s 1 alternate;
            }
          }
        }

        &.sub-head {
          margin: 2em 1em 0em 1em;
          text-transform: uppercase;
          font-weight: 600;
          color: $brand-color;
          opacity: 0.5;

          &:hover {
            background: $white;
          }

          &:first-child {
            margin: 0em 1em 0.5em 1em;
          }
        }

        .icon {
          margin: 0 10px;
          background-color: transparent;
          height: auto;

          span {
            font-size: 1.5em;
          }
        }

        p {
          flex: 1 1;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        &:hover,
        &.active {

          .icon,
          p {
            background-color: transparent;
            color: $brand-color;
          }
        }
      }
    }
  }

  .scroll-area {
    max-height: calc(100vh - 14em);
    overflow-x: hidden;
    padding-right: 4px;
    scrollbar-color: #555969 #e7ecf0;
    scrollbar-width: thin;
    margin-right: -7px;
  }

  .role-scroll {
    max-height: calc(100vh - 14em);
    overflow-x: hidden;
    scrollbar-color: #555969 #e7ecf0;
    scrollbar-width: thin;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    gap: 2em;
    text-align: center;

    li {
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
          .icon {
            background-color: $sky-white;
            color: $menu-color;
          }
        }
      }

      .icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: $sky-white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px auto;
        color: $menu-color;

        span {
          font-size: 1.8em;
        }
      }

      &:hover,
      &.active {
        .icon {
          background-color: $brand-color;
          color: $white;

          span {
            animation: swing ease-in-out 0.5s 1 alternate;
          }
        }
      }
    }
  }

  @include respond(w1200) {
    //display: none;
  }
}

.content-tenderwrap {
  width: calc(100% - 135px);
  margin-left: 123px;
}

.highlight-tender {
  box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%), 0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
  border-radius: $md-radius;
  padding: 10px;
  margin-bottom: 10px;
}

.spc-map {
  .respo-scroll {}
}

.content-wrap {
  width: calc(100% - 140px);
  margin-left: 125px;

  &.collapsed {
    width: calc(100% - 306px);
    margin-left: 294px;
    margin-right: 16px;
  }

  @include respond(w1200) {

    &,
    &.collapsed {
      width: calc(100% - 120px) !important;
      margin-left: 120px !important;
    }
  }
}

.left-content-wrap {
  .tab-head {
    .active {
      border-bottom: 2px solid $brand-color;
      font-weight: 500;
    }
  }
}

.right-content-wrap {
  .sub-heading {
    font-size: 18px;
    font-weight: 600;
    color: $head-black;
  }

  .tab-head {
    cursor: pointer;

    .active {
      border-bottom: 2px solid $brand-color;
      font-weight: 500;
    }
  }
}

.brand-img{
  width: 196px;
}
.main-head {
  font-size: 18px;
  font-weight: 600;
  color: $head-black;
  line-height: 28px;

  .bubble {
    margin-left: 1em;
    background-color: $hover-color;
    color: $white;
    font-weight: normal;
    font-size: 14px;
    padding: 2px 14px;
    border-radius: 2em;
  }
}

.footer {
  display: flex;
  margin: 1em;

  &.data-con {
    width: calc(100% - 140px);
    margin-left: 125px;
  }

  &.collapsed {
    width: calc(100% - 324px);
    margin-left: 302px;

    @include respond(w1200) {
      & {
        width: calc(100% - 124px) !important;
        margin-left: 118px !important;
      }
    }
  }

  .link-btn {
    color: $content-black;
  }
}

.v-list-lg {
  padding: 2.8em 2em 1.6em 8.5em;
  margin-top: 1em;
  position: relative;
  min-height: 8em;
  color: $head-black;
  border-bottom: 2px solid transparent;

  h5 {
    font-weight: 500;
    font-size: 1em;
  }

  .ship-thumb {
    position: absolute;
    left: 26px;
    top: 38px;
    opacity: 0.9;
  }

  .pro-indicators {
    position: absolute;
    right: 2em;
    top: 1em;
  }

  .pin {
    position: absolute;
    top: 0.5em;
    left: 0em;
    color: $grey;
    font-size: 1.2em;
    padding: 10px;
    z-index: 1;

    &.pinned {
      color: $t-ind;
    }
  }

  @include respond(w992) {
    .thmb-lg {
      width: 60px;
      height: 60px;
    }

    .respo-fix {
      --bs-gutter-x: 1em;
      margin-top: 1em;
    }
  }

  &:hover {
    border-bottom-color: $brand-color;

    .ship-thumb {
      opacity: 1;
    }

    .phase-ind {
      animation: swing ease-in-out 0.5s 1 alternate;
    }
  }
}

@mixin phase-ind {
  width: 30px;
  height: 20px;
  font-weight: 500;
  color: $white;
  border-radius: 4px;
  text-align: center;
}

.phase-ind {
  .plan {
    @include phase-ind();
    background-color: $p-ind;
  }

  .specify {
    @include phase-ind();
    background-color: $s-ind;
  }

  .tender {
    @include phase-ind();
    background-color: $t-ind;
  }

  .repair {
    @include phase-ind();
    background-color: $r-ind;
  }

  .close {
    @include phase-ind();
    background-color: $c-ind;
  }

  .notstarted {
    @include phase-ind();
    background-color: $red;
  }
}

.priority-ind {
  font-size: 0.9em;
}

.tab-items {
  height: 2.5em;
  cursor: pointer;
  font-weight: 500;

  &.active {
    border-bottom: 2px solid $brand-color;
    font-weight: 500;
    color: $brand-color;
  }

  &:hover,
  &:active {
    border-bottom: 2px solid $brand-color;
    color: $brand-color;
  }
}

.login-wrap {
  background: url(../../images/login-bg.jpg) no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .card.login-card {
    padding: 2em;
    margin: 6em;
    flex: 0 0 32em;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 1em;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    max-width: 29em;
  }
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.caption {
  margin: 0em 8em -11em 8em;
  color: $white;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 4em;
    font-weight: 600;
    margin-bottom: 0.3em;
    line-height: 62px;
    text-shadow: -1px 3px rgba(0, 0, 0, 0.2);
  }

  h4 {
    font-size: 1.1em;
    font-weight: 400;
    background: rgb(9 35 61 / 50%);
    padding: 10px;
    border-radius: 12px;
  }
}

.spec-list {
  .button-ctrl {
    .link-btn {
      display: none;
    }
  }

  &.side {
    .collapse-head {
      padding: 10px;
      font-weight: 500;
      background-color: $sky-white;
      border-radius: $md-radius;
      color: $head-black;
    }

    .header-spec {
      padding: 5px;
      font-weight: 500;
      border-top: 1px solid $stroke;
      // background-color: $sky-white;
      border-radius: $md-radius;

      color: $head-black;
    }

    .spec-item {
      padding: 6px;
      border-bottom: 1px solid $stroke;
      padding-left: 2.6em;
      border-radius: $md-radius;

      &.active {
        background-color: $brand-color;
        color: $white;
        transition: none;

        &:hover {
          background-color: $brand-color;
        }

        .link-btn {
          color: $white;
        }

        .button-ctrl {
          .link-btn {
            display: block;
          }
        }
      }

      &:hover {
        background-color: $sky-blue;

        .button-ctrl {
          .link-btn {
            display: block;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.scroll-area {
  overflow-y: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bottom-ribbon {
  position: fixed;
  bottom: 0;
  width: 100%;

  .table-sm {
    tr {
      border-top: 1px soid $stroke;
    }

    th {
      background-color: $sky-blue;
      font-weight: 500;
      color: $head-black;
    }

    th,
    td {
      padding: 4px 6px;
    }

    border: 1px solid $stroke;
    min-width: 30em;
    text-align: left;
  }

  .chat-btn {
    width: 100px;
    border: 0;
    background: $sky-blue;
    font-size: 3em;
    height: 1.8em;

    span {
      color: $brand-color;
    }

    &:hover,
    &:focus {
      background: $brand-color;
      outline: none;

      span {
        color: $white;
      }
    }
  }
}

.vessel-info {
  background-color: rgba(0, 0, 0, 0.8);
  // color: $white;
  border-radius: $xl-radius;
  opacity: 0.8;

  .label-white {
    color: $white;
    width: 40%;
  }
}

.content-hide {
  height: 0px;
  overflow: hidden;
  -moz-transition: height 1.5s ease;
  -webkit-transition: height 1.5s ease;
  -o-transition: height 1.5s ease;
  transition: height 1.5s ease;
}

.content-show {
  -moz-transition: height 1.5s ease;
  -webkit-transition: height 1.5s ease;
  -o-transition: height 1.5s ease;
  transition: height 1.5s ease;
}

.height-fix {
  min-height: calc(100vh - 182px);
  max-height: calc(100vh - 182px);
}

.scroll-wrap {
  max-height: calc(100vh - 234px);
  overflow: auto;
  overflow-y: auto;
  padding-right: 6px;
  // min-height: 2em;
  scrollbar-color: #555969 #e7ecf0;
  scrollbar-width: thin;

  &.permission-user,
  &.task-list-height {
    max-height: calc(100vh - 400px);
  }

  &.spc-map {
    max-height: calc(100vh - 332px);
  }

  &.currency-page {
    max-height: calc(100vh - 330px);
  }
}

@mixin thumbnail {
  border-radius: 50%;
  margin-right: 1rem;
  background: url('../../images/img-placeholder.jpg') no-repeat;
  background-size: cover;
}

.thmb-xxlg {
  width: 132px;
  height: 132px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }

  &.user-thmb {
    background: url('../../images/user-placeholder.jpg') no-repeat;
    background-size: contain;
  }
}

.thmb-xlg {
  width: 112px;
  height: 112px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }

  &.user-thmb {
    background: url('../../images/user-placeholder.jpg') no-repeat;
    background-size: contain;
  }
}

.thmb-xlg-card {
  width: 112px;
  height: 80px;
  border-radius: 50%;
  // @include thumbnail();

  img {
    border-radius: 50%;
  }

  // &.user-thmb {
  //   background: url('../../images/user-placeholder.jpg') no-repeat;
  //   background-size: contain;
  // }
}

.thmb-lg {
  width: 72px;
  height: 72px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }
}

.thmb-md {
  width: 46px;
  height: 46px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }
}

.thmb-sm {
  width: 32px;
  height: 32px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }
}

.thmb-xlg {
  width: 132px;
  height: 132px;
  @include thumbnail();

  img {
    border-radius: 50%;
  }
}

.thmb-icon-md {
  width: 46px;
  height: 46px;
  background: $sky-blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: $second-brand-color;
}

.thmb-icon-sm {
  width: 32px;
  height: 32px;
  background: $sky-white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: $brand-color;
}

.thmb-icon-xs {
  width: 22px;
  height: 22px;
  background: $sky-white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: $brand-color;
}

.head-12 {
  font-weight: 600;
  color: $head-black;
  font-size: 12px;
}

.head-14 {
  font-weight: 600;
  color: $head-black;
  font-size: 14px;
}

.head-16 {
  font-weight: 600;
  color: $head-black;
  font-size: 16px;
}

.head-18 {
  font-weight: 600;
  color: $head-black;
  font-size: 18px;
}

.head-20 {
  font-weight: 600;
  color: $head-black;
  font-size: 20px;
}

.or-serator {
  color: #747474;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.stripped-react-table {
  .table {
    .rt-resizable-header-content {
      white-space: normal !important;
      overflow: visible !important;
      text-align: left;
      font-size: 12px;
      font-weight: 600;
    }

    .rt-table {
      .rt-tbody {
        background-color: $white;

        .form-control {
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          color: $menu-color;
          background-color: $white;
          background-clip: padding-box;
          border: 1px solid $stroke;
          border-radius: 0.375rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          line-height: 1.5;
        }

        .rt-td {
          display: flex;
        }

        .rt-tr {
          &:hover {
            background-color: $hover-blue;
          }
        }
      }
    }

    .rt-thead.-header {
      background-color: $white;
      box-shadow: none;
      border-bottom: 1px solid $stroke;
      border-radius: 0px;
      color: $head-black;

      .rt-th {
        display: flex;
      }
    }
  }

  .action-column {
    display: flex;

    button:first-child,
    .link-btn:first-child {
      margin-right: 1.5em;
    }
  }

  .modal-content {
    .modal-input {
      height: 38px !important;
    }

    .css-1s2u09g-control {
      border-radius: 6px;
      border: 1px solid $input-gray;
    }

    .input1-wrap {
      display: flex;
      flex-direction: column;
    }

    .table-sub-content {
      margin-left: 25px;
    }
  }
}

.notify-container {
  border-color: rgba(255, 255, 255, 0.2);

  .sub-head {
    position: absolute;
    top: -11px;
    left: 12px;
    background: $black;
    padding: 4px 8px;
    border-radius: $md-radius;
    font-weight: 500;
    color: $yellow;
  }
}

.vessel-banner {
  // background: url("../../images/bg-ship.svg") no-repeat;
  background-size: 100%;
  height: 70vh;
  padding: 2em;

  .align-fix {
    top: 2em;
    right: 2em;
  }
}

.header-main {
  &.tab-head {
    border-bottom: 2px solid $grey;

    .main-nav .nav-item li {
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid $brand-color;
      }
    }
  }
}

.management-wrap,
.fleet-wrap {
  &:hover {
    background-color: $hover-blue;
  }
}

.card-table {
  box-shadow: $shadow-light;
  background-color: $white;
  border: 1px solid $stroke;
  min-width: 100%;
  text-align: left;

  tr {
    border-bottom: 1px solid $stroke;

    &:hover {
      background-color: $hover-blue;
    }
  }

  th {
    background-color: $sky-blue;
    padding: 0.8em 1em;
    font-weight: 500;
    color: $head-black;
  }

  td {
    padding: 0.8em 1em;
  }
}
.card-table-gantt {
  box-shadow: $shadow-light;
  background-color: $white;
  border: 1px solid $stroke;
  min-width: 100%;
  text-align: left;

  tr {
    border-bottom: 1px solid $stroke;
    

    &:hover {
      background-color: $hover-blue;
    }
  }

  th {
    background-color: $sky-blue;
    padding: 0.8em 1em;
    font-weight: 500;
    color: $head-black;
    border-left: 1px solid $stroke !important;
    border-right: 1px solid $stroke !important;
    min-width: 120px;
  }

  td {
    padding: 0.8em 1em;
    border-left: 1px solid $stroke;
    border-right: 1px solid $stroke;
  }
}
.card-table-close {
  box-shadow: $shadow-light;
  background-color: $white;
  border: 1px solid $stroke;
  min-width: 100%;
  text-align: left;

  tr {
    border-bottom: 1px solid $stroke;

    &:hover {
      background-color: $hover-blue;
    }
  }

  th {
    background-color: $sky-white;
    padding: 0.8em 1em;
    font-weight: 500;
    color: $head-black;
  }

  td {
    padding: 0.8em 1em;
  }
}

/********************************/
.card-table-repair {
  box-shadow: $shadow-light;
  background-color: $white;
  border: 1px solid $stroke;
  min-width: 100%;
  text-align: left;

  tr {
    border: 1px solid $stroke;

    &:hover {
      background-color: $hover-blue;
    }
  }

  th {
    background-color: $sky-white;
    padding: .8em 1em;
    font-weight: 500;
    border: 1px solid $stroke;
    color: $head-black;
  }

  td {
    padding: .8em 1em;
    border: 1px solid $stroke;
  }

}

/********************************/

.dropdown {
  perspective: 1000px;
  z-index: 100;
}

.dropdown:hover .dropdown_menu li {
  display: block;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  perspective: 1000px;
  z-index: 999;
  background-color: $white;
  padding-top: 1.2em;
  box-shadow: $shadow-light;
  border-bottom-left-radius: $md-radius;
  border-bottom-right-radius: $md-radius;
}

.dropdown_menu li {
  display: none;
  color: $content-black;
  padding: 10px 20px;
  opacity: 0;
  cursor: pointer;
}

.dropdown_menu li:hover {
  background-color: $brand-color;
  color: $white;
}

.dddropdown {
  .dropdown_menu li:hover {
    background-color: #EBF2FF;
    color: black;
  }

  .dropdown_menu li {
    display: none;
    color: $content-black;
    padding: 10px 20px;
    opacity: 0;
    cursor: pointer;
  }
}

.searchdropdown {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px 20px;
  // opacity: 0;
  width: 100%;
  perspective: 1000px;
  z-index: 999;
  background-color: $white;
  border: 1px solid #dddd;
  padding-top: 1.2em;
  box-shadow: $shadow-light;
  border-bottom-left-radius: $md-radius;
  border-bottom-right-radius: $md-radius;
}

.dropdown:hover .dropdown_menu--animated {
  display: block;
}

.dropdown_menu--animated {
  display: none;
}

.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}

.dropdown:hover .dropdown_menu--animated-filter {
  display: block;
}

.dropdown_menu--animated-filter li {
  display: block;
  opacity: 1;
}

.down-animate {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@-moz-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@-webkit-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@-o-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.half-width-gap-1 {
  width: calc(50% - 1em);
}

.check-box {
  &.gap-1 {
    label::before {
      margin-right: 1em;
    }
  }
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  font-size: 0.9125em !important;
  border: 1px solid $input-gray !important;
  border-radius: $md-radius !important;

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }
}

.status-select {

  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1insrsq-control {
    font-size: 0.8125em !important;
    border: 1px solid $input-gray !important;
    border-radius: 25px !important;
    height: 30px !important;
    min-height: 30px !important;
    width: 135px !important;

    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
      display: none !important;
    }

    .css-tlfecz-indicatorContainer {
      padding: 4px !important;
    }

    .css-1gtu0rj-indicatorContainer {
      padding: 4px !important;
    }

    .css-tlfecz-indicatorContainer {
      padding: 5px !important;

    }
  }
}

.row-hover:hover {
  background-color: $hover-blue;
}

.subtask {
  background-color: $sky-white;
  border-radius: $md-radius;
}

.docTypeName {
  background-color: $sky-white;
  padding: 0px 12px;
  font-size: 10px;
  border-radius: 30px;
  color: $black;
  white-space: nowrap;

  &.status {
    color: $white;
  }
}

.docTypeName-color {
  background-color: $sky-white;
  padding: 0px 12px;
  font-size: 10px;
  border-radius: 30px;
  color: $black;
  white-space: nowrap;

  &.status {
    color: $brand-color;
  }
}

.text-primaryRed-1 {
  color: $red;
}

.drag-drop-file {
  display: flex;
  background: $sky-white;
  border: 2px dashed $stroke;
  border-radius: $md-radius;
  justify-content: center;
  align-items: center;
  height: 8.5em;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    border-color: $brand-color;
  }

  &.file-added {
    background: #cef0db;
    border-color: #0bb24c;

    span {
      color: #0bb24c;
    }

    .clear-file span {
      color: #d60005;
    }
  }

  &.file-waiting {
    background: $sky-blue;
    border-color: #2269d5;
  }

  &.file-not-added {
    background: #cef0db;
    border-color: red;

    // span {
    //     color: #0bb24c;
    // }

    // .clear-file span {
    //     color: #d60005;
    // }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  .icon-buble {
    width: 4em;
    height: 4em;
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $brand-color;
      font-size: 2em;
    }
  }
}
.drag-drop-files {
  display: flex;
  background: $sky-white;
  border: 2px dashed $stroke;
  border-radius: $md-radius;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    border-color: $brand-color;
  }

  &.file-added {
    background: #cef0db;
    border-color: #0bb24c;

    span {
      color: #0bb24c;
    }

    .clear-file span {
      color: #d60005;
    }
  }

  &.file-waiting {
    background: $sky-blue;
    border-color: #2269d5;
  }

  &.file-not-added {
    background: #cef0db;
    border-color: red;

    // span {
    //     color: #0bb24c;
    // }

    // .clear-file span {
    //     color: #d60005;
    // }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  .icon-buble {
    width: 4em;
    height: 4em;
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $brand-color;
      font-size: 2em;
    }
  }
}

.drag-drop-file-attach {
  display: flex;
  background: $sky-white;
  border: 2px dashed $stroke;
  border-radius: $md-radius;
  justify-content: center;
  align-items: center;
  height: 10em;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    border-color: $brand-color;
  }

  &.file-added {
    background: #cef0db;
    border-color: #0bb24c;

    span {
      color: #0bb24c;
    }

    .clear-file span {
      color: #d60005;
    }
  }

  &.file-waiting {
    background: $sky-blue;
    border-color: #2269d5;
  }

  &.file-not-added {
    background: #cef0db;
    border-color: red;

    // span {
    //     color: #0bb24c;
    // }

    // .clear-file span {
    //     color: #d60005;
    // }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  .icon-buble {
    width: 4em;
    height: 4em;
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $brand-color;
      font-size: 2em;
    }
  }
}

.ql-container {
  height: calc(100vh - 32em) !important;
}

.circle-arrow {
  width: 2em;
  height: 2em;
  background-color: $sky-blue;
  border-radius: 50%;
  padding: 7px 9px;
  font-weight: 600;
  color: $brand-color;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: $brand-color;
    color: $white;
  }
}

.circle-arrow-small {
  width: 1.5em !important;
  height: 1.5em !important;
  padding: 4px 6px !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.major-innertable {
  .rt-tr {
    padding: 8px 0 I !important;
  }
}

.select-height {
  .css-1s2u09g-control {
    height: 39px !important;
  }
}

.role-list {
  &.menu-item {
    gap: 0.5em !important;

    li {
      padding: 8px 10px;
      border-radius: $md-radius;
      font-weight: 500;
      text-transform: capitalize;

      &:hover,
      &.active {
        background-color: $sky-white;
        color: $brand-color;
      }

      .icon {
        margin: 0 !important;
        justify-content: flex-start;
      }
    }
  }
}

.flag {
  img {
    border-radius: $md-radius;
    width: 24px;
    height: 20px;
    border-radius: $md-radius;
  }
}

.attached-file {
  position: absolute;
  background: #fff;
  z-index: 9;
  padding: 0px 10px;
  bottom: 10px;
  align-items: center;
  border-radius: $lg-radius;
}

.card-table-sec .stripped-react-table .ReactTable .rt-tbody .rt-tr-group {
  margin-bottom: 0;
}

.react-multi-email {
  border-radius: 6px !important;
  min-height: 2.9em !important;
}

.react-multi-email [data-tag] {
  font-size: 10px !important;
}

.p-2em {
  padding: 2em;
}

.p-3em {
  padding: 3em;
}

.p-2rem {
  padding: 2rem;
}

.task-timeline {
  .rct-header-root {
    background: #ffffff !important;
    border: none !important;
  }

  .rct-calendar-header {
    border: none !important;
  }

  .rct-dateHeader {
    border-left: none !important;
    border-bottom: 1px solid $stroke !important;
    background-color: $white !important;
    font-weight: 500 !important;
  }

  .rct-dateHeader-primary {
    color: $content-black !important;
    border-right: none !important;
    border-left: none !important;
  }

  .rct-sidebar .rct-sidebar-row {
    border-bottom: 1px solid $stroke !important;
  }

  .rct-vertical-lines .rct-vl {
    border-left: none !important;
  }

  .rct-item {
    border-radius: $lg-radius !important;
    border: none !important;
  }

  .rct-horizontal-lines .rct-hl-odd {
    background: transparent !important;
    border-bottom: 1px solid $stroke !important;
  }

  .rct-horizontal-lines .rct-hl-even {
    background: transparent !important;
    border-bottom: 1px solid $stroke !important;
  }

  .rct-sidebar {
    border-right: none !important;

    .rct-sidebar-row.rct-sidebar-row-odd,
    .rct-sidebar-row.rct-sidebar-row-even {
      background: transparent !important;

      &:hover {
        background: $sky-blue !important;
      }
    }
  }
}

.couter-bubble {
  width: 24px;
  height: 18px;
  border-radius: $md-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sky-blue;
  font-size: 13px;
}

.couter-bubbles {
  min-width: 40px;
  height: 22px;
  padding: 0px 5px;
  border-radius: $md-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sky-blue;
}

.quote-bubble {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #ffffff;
  background-color: #2269D5;
  border-radius: 50%;
  padding: 0px 2px;
  min-width: 22px;
  font-size: 10px;
  height: 20px;
  text-align: center;
  font-weight: 600;
}

.sum-stamp {
  width: 30%;
  border-radius: $md-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sky-blue;
  flex-direction: column;
  padding: 1em;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-20 {
  margin-left: 20px;
}

.attached-file-multi {
  overflow-y: auto;
  max-height: 7em;

  .item {
    background: #fff;
    padding: 6px 10px;
    align-items: center;
    border-radius: 8px;
    display: flex;
  }
}

.attachment-repair {
  overflow-y: auto;
  max-height: 20em;

  .item {
    background: #fff;
    padding: 6px 10px;
    align-items: center;
    border-radius: 8px;
    display: flex;
  }
}

.attached-file-multi-nonspec {
  overflow-y: auto;
  max-height: 5em;

  .item {
    background: #fff;
    padding: 6px 10px;
    align-items: center;
    border-radius: 8px;
    display: flex;
  }
}

.delete-icon-multi {
  color: #d60005;
}

.file-added-multi {
  color: #0bb24c;
}

.status-low {
  background-color: #e5e1e6;
  color: #ffffff;
}

.status-medium {
  background-color: #fa7900;
  color: #ffffff;
}

.status-high {
  color: #ffffff;
  background-color: #d1414b;
}

.status-due {
  color: #ffffff;
  background-color: rgb(22, 181, 249);
}

.status-overdue {
  color: #ffffff;
  background-color: #f22c00;
}

.status-progress {
  color: #ffffff;
  background-color: #9C59B8;
}

.status-complete {
  color: #ffffff;
  background-color: #08b863;
}

.status-na {
  color: #ffffff;
  background-color: #b7b7b7;
}

.status-hold {
  color: #ffffff;
  background-color: #ff9950;
}

.card-disable {
  opacity: 0.7;
}

a.link-disable {
  opacity: 0.7;
  cursor: not-allowed !important;
}

li.icon-disable {
  opacity: 0.7;
  cursor: not-allowed !important;
}

button.icon-disable {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.cursor-disable {
  opacity: 0.7;
  cursor: not-allowed !important;
}

*:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.tb-gt-1 {
  .rt-tbody {
    background-color: $body-gray;
  }

  .rt-td:nth-child(1),
  .rt-td:nth-child(2),
  .rt-td:nth-child(3) {
    background-color: $white;
  }
}

.content-loader {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 18% auto;
  position: relative;
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 1em;
    color: $content-black;
    margin: 1em auto;
    display: block;
    font-weight: 500;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 3px solid $brand-color;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0;
    background: #48a5e5;
    width: 3px;
    height: 24px;
    transform: translateX(-50%);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.invisible-table {
  width: 100%;

  td {
    padding: 6px 0;
  }
}

.row {
  &.gutter-1 {
    --bs-gutter-x: 1rem;
  }

  &.gutter-2 {
    --bs-gutter-x: 2rem;
  }

  &.gutter-3 {
    --bs-gutter-x: 3rem;
  }

  &.gutter-4 {
    --bs-gutter-x: 4rem;
  }

  &.gutter-5 {
    --bs-gutter-x: 5rem;
  }
}

.emergency-header {
  white-space: normal !important;
  overflow: visible !important;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #626d8a !important;
  text-transform: capitalize !important;
}

.page-not-found,
.access-denied {
  margin: 10% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.page-not-found {
  .img {
    width: 195px;
    height: 53px;
    background: url(../../images/not-found.png) no-repeat;
    display: block;
  }
}

.access-denied {
  .img {
    width: 72px;
    height: 89px;
    background: url(../../images/not-allowed.png) no-repeat;
    display: block;
  }
}

.document-side-panel {
  .side-panel__header {
    box-shadow: none;
  }

  .doc-subhead {
    background-color: $white;
    margin: -1.5em -1.8em 0 0em;
    padding: 0.6em 1.6em 0.6em 1.8em;
    border-bottom: 1px solid $stroke;
  }

  .side-panel__content {
    background-color: $body-gray;
    height: calc(100% - 0px);
    padding-left: 0;
    padding-right: 0;
  }

  .left-wrap,
  .right-wrap {
    height: calc(100vh - 80px);
    overflow: auto;
  }

  .left-wrap {
    background-color: $white;
    flex-basis: 24em;
    padding: 1em 2em;
  }

  .right-wrap {
    padding: 1em 2em;
    flex-grow: 3;
  }

  .folder-list {
    margin-bottom: 0.5em;
    font-weight: 500;

    &.active,
    &:hover {
      background-color: $sky-white;
      cursor: pointer;
      border-radius: $md-radius;
    }

    .folder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .folder-list-disabled {
    margin-bottom: 0.5em;
    font-weight: 500;

    .folder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .fold-details {
    font-size: 12px;

    span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .icon-ind {
    color: #4794d7;

    &:hover {
      color: #106cbd;
    }
  }

  .tab-set {
    margin-bottom: 1em;

    .half-width {
      font-weight: 500;
      border-bottom: 2px solid $stroke;
      padding-bottom: 1em;
      cursor: pointer;
      text-align: center;

      &.active,
      &:hover {
        color: $brand-color;
        border-bottom: 2px solid $brand-color;
      }
    }
  }
}

.newfold-box {
  right: 0;
  z-index: 9;
  width: 280px;
  background-color: $sky-blue;
}

.search-project {
  min-width: 28em !important;
}

// .gantt-timeline {
.plan {
  background: $p-ind !important;
  border: 1px solid $p-ind !important;
}

.specify {
  background: $s-ind !important;
  border: 1px solid $s-ind !important;
}

.tender {
  background: $t-ind !important;
  border: 1px solid $t-ind !important;
}

.repair {
  background: $r-ind !important;
  border: 1px solid $r-ind !important;
}

.close {
  background: $c-ind !important;
  border: 1px solid $c-ind !important;
}

.notstarted {
  background: $red !important;
  border: 1px solid $red !important;
}

.Gantt-Task-List_Wrapper {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
}

.Gantt-Task-List_Row {
  display: table-row;
  text-overflow: ellipsis;
}

.Gantt-Task-List_Row:nth-of-type(even) {
  background-color: #f5f5f5;
}

.Gantt-Task-List_Cell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}

.Gantt-Task-List_Cell__Link {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.Gantt-Table {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
  border-right: #e6e4e4 1px solid;
}

.Gantt-Table_Header {
  display: table-row;
  list-style: none;
}

.Gantt-Table_Header-Separator {
  border-right: 1px solid rgb(196, 196, 196);
  opacity: 1;
  margin-left: -2px;
}

.Gantt-Table_Header-Item {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
}

.Gantt-Header {
  list-style: none;
  -ms-box-orient: horizontal;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.Gantt-Button_Header {
  margin: 0.5rem;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.Gantt-Header_Separator {
  text-align: center;
  vertical-align: middle;
  margin-top: 0.5rem;
}

// ._3eULf {
//     max-height: calc(100vh - 18em);
//     overflow-y: auto;
//     overflow-x: hidden;
// }
._2k9Ys::-webkit-scrollbar-thumb {
  border: 8px !important;
  background: #555969 !important;
  border-radius: unset !important;
  background-clip: unset !important;
}

._2k9Ys::-webkit-scrollbar {
  width: 9.1rem !important;
  height: 56px !important;
}

._1eT-t::-webkit-scrollbar-thumb {
  border: 8px !important;
  background: #555969 !important;
  border-radius: unset !important;
  background-clip: unset !important;
}

._1eT-t::-webkit-scrollbar {
  width: 9.1rem !important;
  height: 56px !important;
}

._35nLX {
  fill: #ebf2ff !important;
}

._9w8d5 {
  fill: #091328 !important;
  font-weight: 550 !important;
  font-size: 1em !important;
}

.gw-small {
  min-width: 60px !important;
  max-width: 60px !important;
}

.gw-icon {
  min-width: 20px !important;
  max-width: 20px !important;
}

.table-cell-width-header {
  min-width: 160px;
  max-width: 160px;
  text-align: left;
  color: #091328 !important;
  font-weight: 550 !important;
  font-size: 1em !important;
}

.table-cell-width {
  min-width: 160px;
  max-width: 160px;
  text-align: left;
  cursor: pointer;
}

.table-cell-width-name {
  min-width: 200px;
  max-width: 200px;
}

.table-cell-width-name-header {
  min-width: 200px;
  max-width: 200px;
  color: #091328 !important;
  font-weight: 550 !important;
  font-size: 1.12em !important;
}

.phase-wrapper {
  .left-content-wrap {
    min-height: calc(100vh - 13em);
    max-height: calc(100vh - 13em);

    .spec-list {
      max-height: calc(100vh - 22em);
      overflow-y: auto;
      margin-right: 2px;
    }
  }

  .left-content-spec-wrap {
    min-height: calc(100vh - 20em);
    max-height: calc(100vh - 20em);

    .spec-list {
      max-height: calc(100vh - 22em);
      overflow-y: auto;
      margin-right: 2px;
    }
  }
}

.page-wrapper.tsi-user {
  margin-top: 8em;
}

.gantt-table-header {
  margin-top: 150px;
  margin-left: 50px;
}

.tooltipDefaultContainer {
  background: #fff;
  padding: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.tooltipDefaultContainerParagraph {
  font-size: 12px;
  margin-bottom: 6px;
  color: #666;
}

.tooltipDetailsContainer {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltipDetailsContainerHidden {
  visibility: hidden;
  position: absolute;
  display: flex;
  pointer-events: none;
}

.content-scroller-height {
  max-height: calc(100vh - 23em);
  overflow-y: auto;
  padding-left: 5px;
}

.specify-side {
  .content-scroller-height {
    max-height: calc(100vh - 15em) !important;
  }
}

.project-list-table {
  width: 100%;

  td {
    padding: 1px 0;
    border: none;
  }
}

._2B2zv {
  border-right: 1px solid #e0e0e0;
}

.select-ind {
  background: url(../../images/select-ind.gif) no-repeat;
  width: 140px;
  height: 130px;
  margin: auto;
  background-size: contain;
}

.editor-wrap-description {
  .ql-toolbar.ql-snow {
    display: none !important;
  }

  .ql-container.ql-snow {
    border-radius: $md-radius !important;
  }
}



.ql-container.ql-snow {
  background-color: #fff !important;
  border-radius: $md-radius !important;
  border: 1px solid $stroke !important;
}

.editor-wrap .ql-snow.ql-toolbar,
.description-wrap .ql-snow.ql-toolbar {
  background: $sky-blue !important;
  margin-bottom: 8px !important;
  border-radius: $md-radius !important;
  border-color: $stroke !important;
}

.chat-btn {
  background: $brand-color;
  margin-left: -10px;
  margin-right: -10px;
  height: 82px;
  position: absolute;
  width: 119px;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 10px;

  span {
    font-size: 2.5em;
    color: $white;
  }

  .dot-indicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 6px;
    left: 66px;
    z-index: 2;
    position: absolute;
  }
}

.doughnut-task {
  canvas {
    width: 200px !important;
    height: 200px !important;
  }
}

// .jodit-container textarea {
//     display: contents !important;
// }

.jodit-wysiwyg ul {
  margin-left: 3rem;
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif !important;
}

.jodit-wysiwyg li {
  margin-left: 3rem;
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif !important;
}

.jodit-wysiwyg p,
.jodit-wysiwyg span {
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif !important;
}

.DateInput_input {
  font-size: 13px !important;
  color: black !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #ffffff !important;
  color: black !important;
}

.jodit-ui-group {
  .jodit-ui-group__disc {
    display: none;
  }

  .jodit-ui-group__circle {
    display: none;
  }

  .jodit-ui-group__square {
    display: none;
  }

  .jodit-ui-group__upper-roman {
    display: none;
  }

  .jodit-ui-group__upper-alpha {
    display: none;
  }

  .jodit-ui-group__lower-alpha {
    display: none;
  }

  .jodit-ui-group__lower-greek {
    display: none;
  }

  .jodit-ui-group__lower-roman {
    display: none;
  }
}

.jodit-status-bar {
  display: none;
}

.jj {

  // transition: none;
  ol,
  ul {
    margin: 10px !important;
  }

  ul li {
    list-style: circle;
  }

  ol li {
    list-style: decimal;
  }
}

.jodit-toolbar-button__text {
  display: none;
}

.jodit-status-bar__item-right {
  display: none;
}

.monthmap {
  display: flex;
  text-align: center;

  span {
    font-size: 2.5em;
    color: $white;
  }

  .dot-indicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 6px;
    left: 66px;
    z-index: 2;
    position: absolute;
  }
}

.list-view {
  padding-bottom: 1em;
}

.quote-content {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.jodit-status-bar__item {
  display: none;
}

.chat-btn {
  background: $brand-color;
  margin-left: -10px;
  margin-right: -10px;
  height: 82px;
  position: absolute;
  width: 119px;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 10px;

  span {
    font-size: 2.5em;
    color: $white;
  }

  .dot-indicator {
    width: 10px;
    height: 10px;
    background-color: $red;
    border-radius: 50%;
    top: 6px;
    left: 66px;
    z-index: 2;
    position: absolute;
  }
}

.jodit-container:not(.jodit_inline).jodit_disabled {
  background: white !important;
}

.jodit-toolbar__box {
  background-color: #D5EAFD !important;
}

// .jodit-toolbar-editor-collection
// {
//   display: none;
// }

.label-space {
  label {
    margin-bottom: 6px;
  }
}

.header_sidepanel {
  border-bottom: none !important;
  box-shadow: none !important;
}

.header_sidepanel_border {
  border-bottom: 0px 4px 10px rgb(0 0 0 / 3%);
  box-shadow: 1px solid #ddd;
}

.editor-wrap .jodit-toolbar__box {
  display: none !important;
}

.jodit-container {
  width: 100% !important;
  // height: calc(100vh - 367px) !important;
}

.jodit-wysiwyg {
  word-break: break-all;
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif !important;
}

.description-width .jodit-react-container {
  width: calc(100vh - -85px) !important;
}

.jodit-workplace {
  height: calc(100vh - 435px) !important;
}

.table-striped {
  width: 100%;

  tr {
    border-bottom: 1px solid $stroke;

    th,
    td {
      padding: 6px 8px;
      text-align: left;
      width: auto;
    }

    th {
      font-weight: 500;
    }
  }
}

.repair_dpick {
  .DateRangePicker {
    .DateRangePickerInput {
      .DateInput {
        width: 100px;
      }
    }
  }
}

.r-card {
  padding: 1.8em;

  .doughnut {
    height: 240px;
    width: 240px;
  }

}

.percentage-status {
  text-align: center;
  position: absolute;
  left: 82px;
  top: 94px;
}

.percentage-status2 {
  text-align: center;
  position: absolute;
  left: 76px;
  top: 94px;
}

.jodit-wrapper-specify {
  max-width: calc(100vw - 798px) !important;
  min-width: 100% !important;
  // width: 100% !important;
}

.jodit-wrapper {
  width: calc(100vw - 810px) !important;
  min-width: 100% !important;
}

.compare-quote-classes {
  .data-container-cq {
    margin: 0.5rem 0rem;
    background: #ffffff;
    position: relative;
    align-items: flex-start !important;
    border-radius: 8px;
    border: 1px solid #e1e1ec;
  }

  .data-container-green-cq {
    margin: 0.5rem 0rem;
    background: #ffffff;
    border: #04AF1F 1px solid !important;
    position: relative;
    align-items: center !important;
    border-radius: 8px;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .input-height-cq {
    height: 30px;
  }

  .h-20p {
    height: 20px
  }

  .mt-6p {
    margin-top: 6px;
  }

  .mt-03 {
    margin-top: 0.3rem;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-gray {
    color: #2E3451;
  }

  .font-dim {
    color: #928B8B;
  }

  .font-bl {
    color: #2E3451;
  }

  .font-gray-kpf {
    color: #75809E;
  }

  .font-blue {
    color: #0084FF;
  }

  .font-navy {
    color: #3F4C6F;
  }

  .font-darkblue {
    color: #2269D5;
  }

  .font-lightgray {
    color: #546D92
  }

  .font-yardQuotedEstimate {
    color: #007413 !important
  }

  .font-yardEstimate {
    color: #AE0000 !important;
  }

  .bg-gray-cq {
    background-color: #EBF2FF !important;
  }

  .btn-success {
    background-color: #54BF78 !important;
    color: #ffffff;
  }

  .cursor-auto {
    opacity: 0.45;
    cursor: not-allowed !important;
  }

  .icon-sm {
    height: 15px;
  }

  .icon-md-cq {
    height: 18px;
  }

  .icon-att {
    height: 20px;
    border-radius: 10px;
  }

  .ml-10 {
    margin-left: 4rem;
  }

  .ml-sub {
    margin-left: 5.57rem;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .gap-4 {
    gap: 1rem;
  }

  .horizontal-line-section::before {
    content: " ";
    display: block;
    border-top: 0.5px solid #E1E1EC;
  }

  .horizontal-line-main::before {
    content: " ";
    display: block;
    border-bottom: 0.5px solid #E1E1EC;
  }

  .horizontal-line::before {
    content: " ";
    display: block;
    border-bottom: 0.5px solid #E1E1EC;
  }

  .w-1\/2 {
    width: 50%;
  }

  .w-1\/3 {
    width: 33.333333%;
  }

  .w-2\/3 {
    width: 66.666667%;
  }

  .w-1\/4 {
    width: 25%;
  }

  .w-1\/5 {
    width: 20%;
  }

  .w-2\/5 {
    width: 40%
  }

  .w-1\/6 {
    width: 16.67%;
  }

  .w-2\/6 {
    width: 33.33%;
  }

  .w-4\/6 {
    width: 66.67%;
  }

  .w-5\/6 {
    width: 83.33%;
  }

  .w-1\/10 {
    width: 10%;
  }

  .w-2\/4 {
    width: 50%;
  }

  .w-3\/4 {
    width: 75%;
  }

  .w-35 {
    min-width: 35%;
    width: 35%;
  }

  .w-yardHeader-full {
    max-width: 50rem;
  }

  .w-yardHeader {
    max-width: 18rem;
  }

  .w-90 {
    width: 90%;
  }

  .w-full {
    width: 100%;
  }

  .w-3 {
    width: 3rem;
  }

  .w-4 {
    width: 4rem;
  }

  .w-5 {
    width: 5rem;
  }

  .w-6 {
    width: 6rem;
  }

  .w-7 {
    width: 7rem;
  }

  .w-8 {
    width: 8rem !important;
  }

  .max-w-8 {
    max-width: 8rem;
  }

  .w-9 {
    width: 9rem;
  }

  .w-10 {
    width: 10rem !important;
  }

  .w-yardcomment {
    width: 40rem;
  }

  .w-30 {
    width: 30.3125rem;
  }

  .w-auto {
    width: auto;
  }

  .scrolls-cq {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: -webkit-box;
  }

  .timeline-text-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-header-cq {
    display: flex;
    background-color: #ebf2ff;
    border-right: 2px solid #e0e0eb;
  }

  .header-full {
    border-right: 1px solid #e0e0eb !important;
  }

  .table-header-cq .header-cell {
    font-weight: 500;
    font-size: 12px;
    text-align: center !important;
    border-right: 1px solid #e1e1ec;
    border-bottom: 1px solid #e1e1ec;
    border-top: 1px solid #e1e1ec;
    width: 8.25rem;
    .center {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // .table-header-cq .header-cell:last-child {
  //   border-right: none;
  // }

  .table-header-cq:hover {
    background-color: #EBF2FF !important;
  }

  .compare-quote-table {
    width: 100%;
    height: 100%;
  }

  .compare-quote-table tr:hover {
    background-color: #f2f2f2 !important;
  }

  .compare-quote-table th:last-child {
    border-right: none !important;
  }

  .compare-quote-table th div {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  .compare-quote-table .cell {
    text-align: right;
    color: #5E5E5E;
    border-right: 1px solid #e1e1ec;
    border-bottom: 1px solid #e1e1ec;
    width: 8.25rem;
    span {
      margin-right: 0.5rem;
    }
  }

  .editable-cell {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: auto;
  }

  .uom-select {
    width: 100%;
    height: 75%;
  }

  .display-start {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .display-end {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .compare-quote-table td:last-child {
    border-right: none !important;
  }
  
  .cost-div {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
    background-color: #EBF2FF;
  }

  .cost-details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    background-color: #EBF2FF;
    padding-right: 0.5rem;
  }

  .border-b {
    border-bottom: 1px solid #E8EFF5;
  }

  .border-b-sec {
    border-bottom: 1px solid #E1E1EC;
  }

  .border-r {
    border-right: 1px solid #E8EFF5;
  }

  .bR-2 {
    border-right: 2px solid #e0e0eb;
  }

  .border-t {
    border-top: 0.5px solid #E1E1EC;
  }

  .dot-cq {
    width: 21px;
    height: 14px;
    background: #F2F6FA;
    border-radius: 13px;
  }

  .assignee-bg-blue {
    background: #0084FF;
    color: #ffffff;
    width: 19px;
    height: 19px;
    text-align: center;
  }

  .assignee-bg-gray {
    background: #F5F7FB;
    color: #717171;
    width: 19px;
    height: 19px;
    text-align: center;
    border: 1px solid #E1E1EC;
  }

  .compare-quote-input {
    color: #5E5E5E;
    border: 1px solid #E8EFF5;
    border-radius: 5px;
    width: 60px;
  }

  .inp-lar {
    width: 80px;
  }

  .container-quote-count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: 33px;
    border: 1px solid #D3D3D3;
    border-radius: 10px;
  }

  .text-sm {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .text-serviceline {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .text-md {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .text-lg {
    font-size: 1.35rem;
    line-height: 1.85rem;
  }

  .text-xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .text-2xl {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  .text-3xl {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .mb-container {
    margin-bottom: 7.5rem;
  }

  .mt-5c {
    margin-top: 5.1rem;
  }

  .p-cl {
    height: 40px;
  }

  .padding-blank {
    height: 40px;
    background-color: #ffffff;
  }

  .bg-newsl {
    background-color: #ffffff;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .border-none {
    border-top: 0.5px solid transparent !important;
    border-bottom: 0.5px solid transparent !important;
    border-left: 0.5px solid transparent !important;
    border-right: 0.5px solid transparent !important;
    color: #75809E;
  }

  .border-kpf {
    border-top: 0.5px solid transparent !important;
    border-bottom: 0.5px solid #E1E1EC;
    border-left: 0.5px solid transparent !important;
    border-right: 0.5px solid transparent !important;
    color: #75809E;
  }

  .header-bg-kfp {
    background-color: #EBF2FF;
  }

  .value-input {
    border-top: 0.5px solid #E8EFF5;
    border-left: 0.5px solid #E8EFF5;
    border-right: 0.5px solid #E8EFF5;
    border-bottom: 0.5px solid #E8EFF5;
  }

  .fixed_header table {
    border-collapse: collapse;
    width: 100%;
  }

  .fixed_header th,
  .fixed_header td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .fixed_header thead {
    background: black;
    color: #fff;
  }

  .fixed_header tbody {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }

  .fixed_header tr {
    border: none;
  }

  .fixed_header th {
    background-color: #EBF2FF;
    color: #2E3451;
    font-weight: 500;
    border: none;
  }

  .kfp-list-scroll {
    max-height: 120px;
    overflow: auto;
  }

  .des-w {
    max-width: 14rem;
  }

  .compare-quote-container {
    margin-top: 4.5rem;
    margin-left: 3rem;
    margin-bottom: 3rem;
  }

  .display-none {
    display: none;
  }

  .close-icon-pos {
    margin-right: 14rem;
  }

  .suggestion-list-kfp {
    height: calc(21vh - 10px) !important;
    width: calc(21vw - 10px) !important;
    overflow: auto;
    z-index: 9999;
    position: relative;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 3%);
  }

  .selected-yard-highlight {
    border-top: 1px solid #2269D5;
    border-right: 1px solid #2269D5;
    border-left: 1px solid #2269D5;
    border-bottom: none;
    outline: none;
    border-color: #2269d5;
    box-shadow: 0 0 0 3px rgba(34, 105, 213, .25);
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  ::-webkit-scrollbar-track {
    background: #e0e0eb;
    border-radius: 0px !important;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 0px !important;
    border: 2px solid transparent;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }

  .cq-bg-even {
    background-color: #F3F8F2 !important;
  }

  .left-icon-cq {
    display: none;
    position: fixed;
    top: 50%;
    left: 35%;
    z-index: 6;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0.5px);
    border-radius: 8px;
    padding: 10px;
    margin-left: 0.5rem;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  .vendor {
    left: auto !important;
    display: block !important;
  }

  .right-icon-cq {
    display: none;
    position: fixed;
    top: 50%;
    right: 5px;
    z-index: 6;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0.5px);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
  }

  .cell-left-align-cq {
    text-align: left !important;
  }

  .dot-indicator {
    display: flex;
    width: 9px;
    height: 9px;
    background-color: $red;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
  }

  .width-yard-headers {
    width: 15rem;
  }

  @media (max-width: 1260px) {
    .cl-head {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .width-yard-headers {
      width: 15rem;
    }
  }

  @media (max-width: 1150px) {
    .w-35 {
      width: 41.75rem;
      min-width: 41.75rem;
    }
  }
}

.mb-cq {
  margin-bottom: 6rem;
}

.compare-quote-classes:hover {
  .left-icon-cq {
    display: block !important;
  }

  .right-icon-cq {
    display: block !important;
  }
}

.specs-width {
  width: 30em;
}

.dd-history-container {
  position: absolute;
  top: 0;
  left: 50%;
}

.anim-bord {
  height: auto;
  border-radius: 15px;
}

.sort-margin {
  margin-left: 635px;
  margin-top: 10px;
}

.view-margin {
  position: absolute;
  left: 120px;
}

.sort-marginn {
  margin-left: 705px;
  margin-top: 10px;
}

.align-view {
  text-align: center;
}

.dd-history-bg li:hover {
  background-color: #ebf2ff !important;
  color: #000000;
}

.side-panel-dd .side-panel__container bg-white {
  background: rgba(0, 0, 0, 0.6) !important;
  opacity: 0.4 !important;
}

.expired-img {
  height: 100px;
}

.side-panel-viewmore .side-panel.side-panel--is-visible::after {
  opacity: 0.4 !important;
}

.margin-foot {
  margin-right: 180px;
}

.mr-foot {
  margin-right: 20px;
}

.text-left-align {
  text-align: end;
}

.q-table-mt1 {
  margin-top: 1px;
}

.tooltip {
  scale: 0.7;
  width: max-content;
}

// .tooltip-w3 {
//   width: 300px;
// }
.tooltip-w5 {
  width: 10px;
}

.mg-left {
  margin-right: 40px;
}

.tooltip-scale {
  scale: 0.5;
}

.width-manage {
  max-width: 1450px;
  margin: 5px auto;
}

// .tooltip-compare
// {
//   position: relative;
//   left: 0;
// }
.side-wid {
  min-width: calc(100%);
}

.jodit-bg .jodit-toolbar-button__button:hover {
  background-color: #fff !important;
}

// .tool-width
// {
//   width: max-content;
// }
.card-width {
  width: 50.9%;
}

.card-vertical {

  height: 276px;
  width: 526px;
}

.mrg-close {
  margin-left: 200px;
}

.vertical-align {
  vertical-align: top !important;
}

.left-o {

  left: 25% !important;

}

.spec-o {

  left: 20% !important;

}

.spec-i {

  left: 28% !important;

}

.dashboard-dropdown {
  position: absolute;
  width: 300px;
  padding-left: 30px;
  top: 100%;
  left: 57%;
  perspective: 1000px;
  z-index: 999;
  background-color: #fff;
  padding-top: 1.2em;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0 1px 2px rgba(204, 210, 218, 0.07),
    0 2px 4px rgba(204, 210, 218, 0.07),
    0 4px 8px rgba(204, 210, 218, 0.07),
    0 8px 16px rgba(204, 210, 218, 0.07),
    0 16px 32px rgba(204, 210, 218, 0.07),
    0 32px 64px rgba(204, 210, 218, 0.07);
}

.dropdown_info {
  position: absolute;
  top: 377%;
  left: 0;
  width: 116px;
  perspective: 1000px;
  z-index: 999;
  background-color: #fff;
  padding-top: 1.2em;
  box-shadow: 0 1px 2px rgba(204, 210, 218, 0.07), 0 2px 4px rgba(204, 210, 218, 0.07), 0 4px 8px rgba(204, 210, 218, 0.07), 0 8px 16px rgba(204, 210, 218, 0.07), 0 16px 32px rgba(204, 210, 218, 0.07), 0 32px 64px rgba(204, 210, 218, 0.07);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.drop_down_more {
  width: 120px !important;
  top: 64px !important;
}

.creat-p {
  margin-left: 122px;
}

@media only screen and (max-width: 1300px) {
  .creat-p {
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 1300px) {
  body {
    font-size: 13px;
  }

  .right-content-wrap .sub-heading {
    font-size: 16px;
  }

  .phase-wrapper {
    .card.p-5 {
      padding: 2rem !important;
    }
  }

  .spec-responsive {
    font-size: 12px;
    align-items: flex-start !important;
    margin-top: -10px !important;

    .check-box {
      margin-top: 2px;
    }
  }

  .page-wrapper.tsi-user {

    .side-menu-wrap {
      width: 90px !important;
      padding: 7em 0.4em 0.8em 0.4em !important;
    }

    .content-wrap {
      width: calc(100% - 112px) !important;
      margin-left: 104px !important;
    }

    .chat-btn {
      width: 100px !important;
    }

    .comments-btn {
      margin-left: 104px;
    }
  }

  .content-tenderwrap {
    width: calc(100% - 120px) !important;
    margin-left: 104px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .comments-btn {
    margin-left: 14px !important;
  }

  .page-wrapper.tsi-user {

    .content-wrap,
    .content-tenderwrap {
      width: calc(100% - 20px) !important;
      margin-left: 10px !important;
    }

    .side-menu-wrap {
      display: none;

      &.show {
        display: block;
        padding-top: 26px !important;
        top: 72px;
      }
    }
  }

  .respo-menu-btn {
    display: block;
  }
}

@media only screen and (min-width: 2200px) {

  .content-wrap,
  .content-tenderwrap,
  .footer {
    max-width: 1500px !important;
    margin: 0 auto !important;
  }
  .phase-wraper{
    max-width: 1500px !important;
    margin: 8em auto 0 auto !important;
  }
}

.gant-tabl {
  z-index: 5;
}

.notification-list {
  li {
    padding: 10px;
    border-bottom: 1px solid $stroke;

    &:hover {
      background-color: $sky-white;
      border-radius: $lg-radius;

      .head-14 {
        color: $brand-color;
      }

      .circle-ico-btn {
        background-color: $brand-color;
        color: white;

        .icon-notification-on.brand-color {
          color: $white;
        }
      }
    }
  }
}

.sum-scroll {
  max-height: 296px;
  overflow-y: scroll !important;
  overflow-x: hidden;
  margin: 10px 10px 14px 20px;
}

@media only screen and (max-width: 991px) {
  .respo-fixr {
    margin-top: 14px;
    margin-bottom: 14px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.page-wrapper1{
  .page-wrapper{
    margin-top: 8.9em;
  }
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.image-gallery{
  .side-panel__content{
    padding-top: 0px;
  }
}

.headrole-user:hover{
  background-color: $sky-blue;
}
@media only screen and (max-width: 992px) {
  .main-head {
    font-size: 13px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1230px) {
  .Currency-media {
    font-size: 7px;
    font-weight: 700;
  }
}
@media only screen and  (max-width: 992px){
  .vessel-info-media{
    font-size: 10px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1230px){
  .quote-media{
    font-size: 8px !important;
    font-weight: bolder;
  }
}

.audit-msg
{
  border-radius: $md-radius;
  padding: 10px;
  background-color: $sky-white;
}
.audit-status{
  border-radius: $md-radius;
  font-size:13px;
  padding: 2px 5px;
  font-weight: 500;
  &.create
  {
    color: $green;
    background-color:#DBF6DF;
  }
  &.update
  {
    color: $brand-color;
    background-color: $sky-white;
  }
  &.delete
  {
    color: $red;
    background-color:#F4E9E9 ;
  }
}
.audit-tab {
  li {
    padding: 10px;
    border-bottom: 1px solid $stroke;
  }
}
.image-audit
{
  width: 38px;
  border-radius: 50%;
}
.audit-wrap
{
  word-break: break-all;
}
.audit-row
{
  position: relative;
  margin-bottom: 10px;
  border-bottom: 1px solid $stroke;
  padding-bottom: 10px;
  .content
  {
    margin-left: 5em;

  }
  .profile-pic
  {
   position: absolute;
   left: 5px;
   top: 3px;
  }
  .auidt-img
  {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
}
@media only screen and (max-width: 992px) {
  .vessel-banner{
    margin-top: 275px;
  }
}

.w-1\/4 {
  width: 27%;
}

.w-3\/4 {
  width: 73%;
}

.picky__dropdown {
  max-height: 250px !important;
  .option {
    display: flex;
    width: 100% !important;
    word-break: break-word;
  }
}

.picky__filter__input:focus,
.picky__filter__input:active,
.picky__filter__input:hover {
  border: 1px solid #185bd9 !important;
}

.username-label {
  padding: 5px;
  width: fit-content;
  word-break: break-word;
  font-style: italic;
  font-weight: 500;
}

.validation-message {
  font-size: 13px;
  font-weight: 500;
  margin-top: 0.5rem;
}

.spec-import-excel {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
}

.display-none {
  display: none;
}

.br-icon {
  border-radius: 5px;
}

.word-break {
  word-break: break-word;
}

.deferr {
  .scroll-wrap {
    min-height: 250px;
  }
  .height-auto {
    min-height: auto !important;
  }
}

.spec-attach-scroll {
  max-height: 25vh;
  overflow-y: auto;
}

.ac-check {
  display: flex;
  margin-left: 50%;
}

.icon-delete:focus, .icon-delete:active{
  outline: none !important;
}

.role-list-h {
  max-height: 275px;
  overflow-y: auto;
}


.t-menu {
  perspective: 1000px;
  z-index: 100;
}

.t-menu:hover .t-menu-btns li {
  display: block;
}

.t-menu-btns {
  position: absolute;
  top: 100%;
  right: 0;
  width: calc(18vw);
  perspective: 1000px;
  z-index: 999;
  background-color: $white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: $md-radius;
  border-bottom-right-radius: $md-radius;
}

.t-menu-btns li {
  display: none;
  color: $content-black;
  padding: 10px 20px;
  opacity: 1;
  cursor: pointer;
}

.t-menu-btns li:hover {
  background-color: $brand-color;
  color: $white;
}

.new-specList {
  max-height: calc(33vh);
  overflow-y: auto;
}

.pre-q .modal-body {
  overflow-y: hidden !important;
}

.pre-q .modal-header {
  margin-bottom: 0.5rem !important;
}

.new-Supp-BG {
  border-left: 6px solid #fff4D7;
  background-color: #fff4D7;
  border-radius: 5px;
}

.w-2 {
  width: 2rem;
}

.gap-1 {
  gap: 1em;
}

.removed-SL {
  opacity: 0.5;
  text-decoration: line-through;
}

.rfq-date {
  display: flex;
  align-items: center;
  width: 54%;
  font-weight: 600;
  font-size: 13px;
}

.rfq-h {
  position: relative;
}

.rfq-h:hover .rfq-details {
  display: block;
}

.rfq-details {
  display: none;
  position: absolute;
  font-size: 14px;
  top: 25%;
  left: 25%;
  height: auto;
  width: calc(30vw);
  background-color: #ffffff;
  border: 0.5px solid #ececec;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

.yards-dropdown .picky__dropdown {
  max-height: calc(35vh) !important;
}

.des-height .jodit-workplace {
  margin-top: 0.5rem;
  height: calc(42vh - 10px) !important;
}

.yard-select-header {
  background: aliceblue;
  padding: 0.75rem;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: small;
  .yardName {
    margin-left: 3.5rem
  }
}

.yardSent-details {
  font-size: 1.25rem;
  font-weight: 600;
}

.dashboard-modal {
  .modal-body {
    height: calc(60vh - 10px);
    overflow-y: auto;
  }
}

.kfp {
  .modal-header {
    margin-bottom: 0.5em !important;
  }
  .modal-body {
    max-height: 65vh !important;
  }
}

.service-grid {
  display: flex;
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  span {
    font-weight: 600;
  }
  .num {
    width: 12%;
  }
  .name {
    width: 50%;
  }
  .uom {
    width: 13%;
  }
  .qty {
    width: 13%;
  }
  .uprice {
    width: 12%;
  }
}
