@import  '../base/reset';
@import  '../base/variables';
@import '../base/mixins';

.page-wrapper {
    margin-top: 7.9em;
}

.header{
    background-color:$white ;
    padding: 0rem 1rem 0rem 1rem;
    z-index: 999;
    position: fixed;    
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid $stroke;

    &-main{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 58px;

        .brand-logo-img{
            width: 150px;
        }


        .project-ribbon {
            background: $sky-blue;
            border-radius: 6px;
            display: grid;
            grid-template: "67px auto";
            grid-gap: 0.4rem;
            padding: 1rem 5rem 1rem 1rem;
            position: relative;
            font-size: 13px;
            justify-content: start;
            margin-bottom: 1em;

            .thmb-md {
                width: 42px;
                height: 42px;
                background: #ccc;
                border-radius: 50%;
                margin-right: 1rem;
                grid-row-start: 1;
                grid-row-end: 3;
                align-self: center;
            }

            h4{
                grid-column-start: 2;
                align-self: start;
                font-size: 14px;
                color: $content-black;
                font-weight: 500;
                margin-top: 5px;
            }

            ul{
                grid-column-start: 2;
                display: flex;
                justify-content: flex-start;
                gap: 1em;
            }

            .close-ico-btn{
                border: none;
                position: absolute;
                top: 12px;
                right: 15px;
                font-size: 1.6em;
                color: $brand-color;

            }
           
        }

    }

    &-sub{
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .nav-toggle{
        margin:.3em 1em;
        display: none; 
        @include respond(w992){
           display: inline-block;     
        }
        a{
            font-size: 20px;
            color: $content-black;
            padding: 6px 10px;

            border: 1px solid #cccc;
            border-radius: $md-radius;
            &:hover{
                background-color: $brand-color;
                color: $white;
            }
        }
    }

}
.show-sm{
    display: none;
}
@media only screen and (max-width: 990px) {
    .show-sm{
        display: block;
    }
    .sm-hide{
        display: none;
    }
    .main-nav  {
        .nav-item{
            background-color: rgba($color: #ffffff, $alpha: 6.0);
            position: absolute;
            margin: 0.3em 1em;
            right: 0px;
            top: 56px;
            min-width: 300px;
            padding: 12px 20px;
            box-shadow: 0 1px 2px rgb(204 210 218 / 7%), 0 2px 4px rgb(204 210 218 / 7%), 0 4px 8px rgb(204 210 218 / 7%), 0 8px 16px rgb(204 210 218 / 7%), 0 16px 32px rgb(204 210 218 / 7%), 0 32px 64px rgb(204 210 218 / 7%);
            border-bottom-left-radius: $md-radius;
            border-bottom-right-radius: $md-radius;
            animation: rotateMenu 400ms ease-in-out forwards;
            transform-origin: top center;
            z-index: 9999;
            flex-direction: column;
            display: none !important;
        }
    

        &.inner{
            position: inherit;
            background: transparent;
            box-shadow: none;
            padding: 6px 0;
            margin: auto;
            min-width: auto;
            z-index: 9;
        }

        // ul{
        //     display: none;
        // }

        &.show .nav-item{
            display: block !important;
        }
        // ul{
        //     flex-direction: column;
        // }
    }
    .md-menu {
        display: block  !important;
    }
    .md-menu-ctrl {
        display: none  !important;
    }
}



.usermenu-item{
    .item-set{
        display: flex;
        grid-auto-columns: 1rem;
        .u-box{
            align-items: flex-start !important;
            .user-nme{
                font-weight: 500;
            }

            .role{
                font-size: 13px;
                opacity: 0.8;
            }

            @include respond(w1200){
               display: none !important 
            }
        }

        .dropdown_menu{
            @include respond(w1200){
                width: 380px;
                left: -222px;
                top: 40px;
             }
        }
    }


    .dot-indicator{
        width: 10px;
        height: 10px;
        background-color: $red;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

.profile-img  {
    img{
    border-radius: 50%;
    width: 38px;
    height: 38px;
    margin-right: 10px;
}
    
}

.sub-header {
    padding: 6px 1.5rem;
    border-bottom: 1px solid $stroke;
    box-shadow:$shadow-light;
    z-index: 9;
    position: fixed;
    top: 59px;
    left: 0;
    width: 100%;
    background-color: $white;

    @include respond(w1200){
        .col-gap-2 {
            column-gap: 1em;
        }
    }
    
    .rnd-btn{
        padding-left: 1.8em;
        padding-right: 1.8em;
    }
    
    .item-set{
        font-size: 1em;
        font-weight: normal;
        align-items: center;

          button{
            background-color: transparent;
            color: $head-black;
            border: none;
            white-space:nowrap;

            &:hover,&.active{
                background-color: $brand-color;
                color: $white;
            }

           
        }
    }
}
.sub-header2{
    button{
        background-color: transparent;
        color: $head-black;
        border: none;
        white-space:nowrap;

        &:hover,&.active{
            background-color: $brand-color;
            color: $white;
        }

       
    }
}

.main-nav{
    .nav-item{
        column-gap: 2em;
        flex-wrap: wrap ;

        .md-menu-ctrl{
            display: none;
        }

        li{
            padding: 13px 0px 16px 0px;
            border-bottom: 2px solid $white;
            font-weight: 500;
            cursor: pointer;
            font-size: 14px;
            color: #000;

            &.md-menu{
                @include respond(w1300){
                    display: none;
                }
            }

            &.md-menu-ctrl{
                @include respond(w1300){
                    display: inline-flex;
                }
            }

            &.active, &:hover{
                color: $brand-color;
                border-bottom: 2px solid $brand-color;
                
            }
        }
    }
}


.thmb-md{
    width: 48px;
    height: 48px;
    background: #ccc;
    border-radius: 50%;
    margin-right: 1rem;
    img {
        border-radius: 50%;
    }
}
.thmb-contact{
    width: 80px;
    height: 80px;
    background: #ccc;
    border-radius: 100%;
    img {
        border-radius: 50%;
    }
}
.sub-header-quote {
    padding: 6px 1rem;
    border: 1px solid #eee;
     top: 59px;
     max-width: calc(100% - 32px);
    overflow-x: auto;
    left: 0;
    width: 100%;
    background-color: #ecf2ff;
    border-radius: 50px;
    
    .rnd-btn{
        padding-left: 1.8em;
        padding-right: 1.8em;
    }
    
    .item-set{
        font-size: 1em;
        font-weight: normal;
        align-items: center;

          button{
            background-color: transparent;
            color: $head-black;
            border: none;
            white-space:nowrap;

            &:hover,&.active{
                background-color: $brand-color;
                color: $white;
            }

           
        }
    }
}

