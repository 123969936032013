.react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: none;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 100px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    border: 2px solid red;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    box-sizing: border-box !important;
    /* border-color: #757575 !important; */
    /* border: #757575 !important; */
    border: 0.1px solid  #EBF2FF !important;
  }
  /* display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-rows: 17rem */

    .react-calendar__year-view__months{
        display: grid !important;
    grid-template-columns: repeat(4,1fr);
    grid-auto-rows: 15.8rem 
    }
    .react-calendar__decade-view__years{
        display: grid !important;
        grid-template-columns: repeat(4,1fr);
        grid-auto-rows: 16rem 
    }
  .react-calendar__tile {
    max-width: 100%;
    position: relative;
    background: none;
    text-align: center;
    line-height: 14px;
    display: flex;
    padding: 2rem 0.5rem !important;
    font-size: 1.1em;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }
  .react-calendar__tile abbr{
      opacity: 0.75;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #EBF2FF;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #EBF2FF ;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    border: 1px solid #2269D5 !important;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  .react-calendar__tile:enabled:focus {
    background-color: transparent !important;
    border: 1px solid red !important;
}

.year{
  font-size: 22px;
  font-weight: 600;
  margin: 0 auto;
  text-align: center;
  padding: 4px;
  color: #495057;
  font-family: "Poppins", sans-serif;
}

.yearslist{
    background-color: #2269D5;
    color: white;
}

.doughnut{
    height: 300px;
    width: 300px;
    margin: 0 auto;
  }
.doughnut-dashboard{
    height: 230px;
    width: 235px;
    margin: 0 auto;
  }

  .scroll-wrap-year {
    overflow-y: auto;
    padding-right: 6px;
    /* min-height: 14em; */
    height: 514px;
    scrollbar-color: #555969 #e7ecf0;
    scrollbar-width: thin;
    border-bottom-left-radius: 6px;
}


.react-calendar__tile-count{
    background-color: #EF686A;
    padding: .5rem .5rem;
    border-radius: 100vmax;
    font-size: .875rem;
    color: white;
    position: absolute;
    top: 1.5rem;
    right: 6px;
    min-width: 2.5rem;
}

.calendar-year-ribbon{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    gap:.75rem

}

.calendar-year-ribbon li{
    padding: .4rem 1rem; 
    border-radius: 4px;
    flex: 1;
    border:1px solid rgba(255, 255, 255, 0);
}

.calendar-year-ribbon li:hover{
  border:1px solid rgba(255, 255, 255, 0.5);
}

.calendar-year-ribbon li.focusYear{
    background: #6197E8 !important;
}

.calendar-height{
  height: 570px;
}

.project-height{
  height: 470px;
}

.scroll-wraps{
  overflow-y: auto;
  padding-right: 6px;
  min-height: 14em;
  max-height: calc(100vh - 850px);
  scrollbar-color: #555969 #e7ecf0;
  scrollbar-width: thin;
  border-bottom-left-radius: 6px;
}
.paginationFooterHeight{
  height: 300px;
}

  