@font-face {
  font-family: 'dd-icon';
  src:  url('../../../icons/icomoon.eot?oex1oz');
  src:  url('../../../icons/icomoon.eot?oex1oz#iefix') format('embedded-opentype'),
  url('../../../icons/icomoon.woff?oex1oz') format('woff'),
    url('../../../icons/icomoon.ttf?oex1oz') format('truetype'),
    url('../../../icons/icomoon.svg?oex1oz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dd-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-send:before {
  content: "\e95c";
}
.icon-help-book:before {
  content: "\e95b";
}
.icon-whatsapp:before {
  content: "\e95a";
}
.icon-image:before {
  content: "\e957";
}
.icon-clock:before {
  content: "\e958";
}
.icon-side-pannel:before {
  content: "\e959";
}
.icon-rain:before {
  content: "\e955";
}
.icon-robot:before {
  content: "\e956";
}
.icon-exclamation_fill:before {
  content: "\e950";
}
.icon-eye-close:before {
  content: "\e951";
}
.icon-globe:before {
  content: "\e952";
}
.icon-sort-down:before {
  content: "\e953";
}
.icon-sort-up:before {
  content: "\e954";
}
.icon-add-folder:before {
  content: "\e94a";
}
.icon-add-user-fill:before {
  content: "\e94b";
}
.icon-message-square:before {
  content: "\e94c";
}
.icon-not-allowed:before {
  content: "\e94d";
}
.icon-rfq-manual:before {
  content: "\e94e";
}
.icon-upload-cloud:before {
  content: "\e94f";
}
.icon-user-filled:before {
  content: "\e949";
}
.icon-add-comment:before {
  content: "\e93d";
}
.icon-add-user:before {
  content: "\e93e";
}
.icon-attachments:before {
  content: "\e93f";
}
.icon-date:before {
  content: "\e940";
}
.icon-dotted-menu-horizontal:before {
  content: "\e941";
}
.icon-dotted-menu-vertical:before {
  content: "\e942";
}
.icon-folder:before {
  content: "\e943";
}
.icon-folder-open:before {
  content: "\e944";
}
.icon-Info:before {
  content: "\e945";
}
.icon-info-question:before {
  content: "\e946";
}
.icon-user:before {
  content: "\e948";
}
.icon-cell-phone:before {
  content: "\e938";
}
.icon-email:before {
  content: "\e939";
}
.icon-location1:before {
  content: "\e93a";
}
.icon-phone:before {
  content: "\e93b";
}
.icon-save:before {
  content: "\e93c";
}
.icon-art-board:before {
  content: "\e921";
}
.icon-blocks:before {
  content: "\e922";
}
.icon-calculater:before {
  content: "\e923";
}
.icon-check-list:before {
  content: "\e92d";
}
.icon-components:before {
  content: "\e92e";
}
.icon-dollar-circle:before {
  content: "\e92f";
}
.icon-money-card:before {
  content: "\e930";
}
.icon-stop-watch:before {
  content: "\e931";
}
.icon-tag:before {
  content: "\e932";
}
.icon-task1:before {
  content: "\e933";
}
.icon-two-gear:before {
  content: "\e934";
}
.icon-vessel:before {
  content: "\e935";
}
.icon-yard:before {
  content: "\e936";
}
.icon-office360:before {
  content: "\e937";
}
.icon-pin:before {
  content: "\e91e";
}
.icon-filter-set:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-check-rnd:before {
  content: "\e910";
}
.icon-add-chat:before {
  content: "\e911";
}
.icon-circle:before {
  content: "\e912";
}
.icon-disc:before {
  content: "\e913";
}
.icon-add-file:before {
  content: "\e914";
}
.icon-eye:before {
  content: "\e915";
}
.icon-chat:before {
  content: "\e916";
}
.icon-filter1:before {
  content: "\e917";
}
.icon-close-line:before {
  content: "\e918";
}
.icon-edit:before {
  content: "\e919";
}
.icon-arrow-thin-right:before {
  content: "\e91a";
}
.icon-arrow-thin-left:before {
  content: "\e91b";
}
.icon-arrow-thin-up:before {
  content: "\e91c";
}
.icon-arrow-thin-down:before {
  content: "\e91d";
}
.icon-sort:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e901";
}
.icon-component:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-dms:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e906";
}
.icon-Frame:before {
  content: "\e907";
}
.icon-meeting:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-notification-on:before {
  content: "\e90a";
}
.icon-plus-square:before {
  content: "\e90b";
}
.icon-poha:before {
  content: "\e90c";
}
.icon-settings:before {
  content: "\e90d";
}
.icon-task:before {
  content: "\e90e";
}
.icon-woha:before {
  content: "\e90f";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-file-picture:before {
  content: "\e927";
}
.icon-file-music:before {
  content: "\e928";
}
.icon-file-play:before {
  content: "\e929";
}
.icon-file-video:before {
  content: "\e92a";
}
.icon-file-zip:before {
  content: "\e92b";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-location:before {
  content: "\e947";
}
.icon-cog:before {
  content: "\e994";
}
.icon-hammer2:before {
  content: "\e9a8";
}
.icon-lab:before {
  content: "\e9aa";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-ctrl:before {
  content: "\ea50";
}
.icon-filter:before {
  content: "\ea5b";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-libreoffice:before {
  content: "\eae3";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
