@import '../../../../../src//styles/sass//base/variables' ;

.fleet-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 6px;
    margin: 10px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    font-weight: 400;
    font-size: 14px;
    .section1 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // color: $menu-color;
        width: 50%;
        .sub-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            span {
               width: 33%;
            }
        }
    }
    .detail-sec {
        width: 20%;
    }
    .btn-section {
        width: 150px;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        :first-child {
            margin-right: 10px;
        }
    }
}
.add-fleet {
    .vessel-table {
        border: 1px solid $stroke;
        .list {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 5px;
            border-bottom: 1px solid $stroke;
            .selected-item {
                background-color: $sky-blue;
                color: $brand-color;
                border-radius: 17px;
                margin: 5px;
                width: fit-content;
                padding: 2px 10px;
                display: flex;
                align-items: center;
            }
            .item-options {
                width: fit-content;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                label {
                    padding-left: 5px;
                }
            }
        }

    }
}
.modal-input {
    height: 38px !important;
}
.css-1s2u09g-control {
    border-radius: 6px;
    border: 1px solid $input-gray;
}

.repair-select__control {
    min-height: 30px !important;
    max-height: 30px !important;
    border-radius: 30px !important;

    &.--is-focused{
    min-height: 30px !important;
    max-height: 30px !important;
    }

    .repair-select__dropdown-indicator{
        padding: 6px 6px 6px 0px !important;
    }

    .repair-select__value-container{
        padding: 0 6px 2px 6px !important
    }
}

.DateRangePicker {
    .DateInput_input{
        font-weight: 500 !important;
        padding: 6px 6px 6px !important;
    }
    .DateInput{
        width: 92px !important;
    }
}

.repair_dpick{
    .DateInput_input{
        border: none;
    }
    .DateRangePickerInput {
        height: 32px;
    }
}
@media only screen and (max-width: 1340px) {
    .repair_dpick{
        .DateInput_input {
            font-size: 12px !important;
        }
        .DateRangePicker .DateInput_input{
            padding: 4px !important
        }
        .DateRangePicker .DateInput {
            width: 80px !important;
        }
        .DateRangePickerInput_arrow_svg {
            height: 16px;
            width: 16px;
        }
        .DateRangePickerInput {
            height: 32px;
            width: 184px;
        }
    }
  }
