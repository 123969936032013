
@mixin respond($breakpoint){
    @if $breakpoint == w576{
        @media only screen and (max-width:576px){@content}
    };

    @if $breakpoint == w768{
        @media only screen and (max-width:768px){@content}
    };
    
    @if $breakpoint == w992{
        @media only screen and (max-width:992px){@content}
    };

    @if $breakpoint == w1000{
        @media only screen and (max-width:1000px){@content}
    };

    @if $breakpoint == w1100{
        @media only screen and (max-width:1100px){@content}
    };

    @if $breakpoint == w1200{
        @media only screen and (max-width:1200px){@content}
    };

    @if $breakpoint == w1300{
        @media only screen and (max-width:1300px){@content}
    };

    @if $breakpoint == w1400{
        @media only screen and (max-width:1400px){@content}
    }
};


/** @mixin respond($breakpoint){
    @if $breakpoint == x-small{
        @media only screen and (min-width:36em){@content}
    };
    @if $breakpoint == small{
        @media only screen and (max-width:36em){@content}
    };
    
    
    @if $breakpoint == medium{
        @media only screen and (max-width:48em){@content}
    };

    @if $breakpoint == large{
        @media only screen and (max-width:62em){@content}
    };

    @if $breakpoint == x-large{
        @media only screen and (max-width:75em){@content}
    };

    @if $breakpoint == xxl{
        @media only screen and (max-width:87.5em){@content}
    }
};*/







